html, body, #reactapp, .App {
  height: 100vh;
}

.App {
  background-color: #282c34;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content:space-between;
  color: white;
}

.Main {
  width: 100%;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content:start;
  color: white;
}

.MyHeader {
  width: 95%;
  margin:5;
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content:space-between;
  color: white;
}

.MyTag {
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content:space-between;
}

.Hash{
  color: white;
  font-size: calc(2vmin);
}


.JobDescription{
  font-size: calc(7px + 2vmin);
}

.JobDetail{
  font-size: calc(4px + 2vmin);
}

.Sketch{
  width: 100%;
  display: flex;
  justify-content: center;
}

